<template>
<!-- eslint-disable -->
	<div class="mt-8">
		<h1 class="ml-8 mb-6">takeno Shop</h1>
		<v-card
			v-for="item in this.productList" :key="item.id"
			class="mx-auto mt-4 slideIn shiny"
			:class="ribbon(item.sale_flg)"
			max-width="350"
			height="150"
			elevation=5
		>
			<v-card-text style="padding-bottom: 0;">
				<h1 v-if="item.sale_flg" style="color: black;">SALE</h1>
				<div style="padding-top: 40px">
					<p class="text-center" style="font-size: 32px;">{{ item.product_name }}</p>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					outlined
					style="width: 90px;"
					@click="createQRCode(item.product_name, item.price)"
				>￥{{ item.price }}</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>
<script>
/* eslint-disable */
import * as api from '@/api.js'
export default {
	name: 'Purchase',
    data () {
      return {
		  productList: [],
     	}
    },

	methods: {
		load() {
			this.$store.dispatch('nowLoading', true)
			api.takeno.shop().then((res) =>{
				switch(res.data.status){
					case 200:
						this.productList = res.data.content
						break
					case 500:
						this.pop('エラー', res.data.error[0])
						break
				}
			}).catch(this.$store.dispatch('nowLoading', false))
		},
		createQRCode(name, price){
			this.$store.dispatch('nowLoading', true)
			api.takeno.purchase({ name: name, amount: price }).then((res) => {
                switch(res.status) {
                    case 200:
						window.open(res.data)
                        break
                    case 500:
						this.pop('エラー', res.data.error[0])
                        break
                }
			}).catch(this.$store.dispatch('nowLoading', false))
		},
		ribbon(sale_flg) {
			return (sale_flg == 1) ? 'ribbon' : ''
		},
	},
	created() {
		this.load()
	},
}
</script>

<style scoped>
/* ボタン */
.v-btn--outlined {
  border: 3px solid #b465da;
  border-image: linear-gradient(to right, #b465da 0%, #cf6cc9 50%, #ee609c 100%);
  border-image-slice: 1;
}
/* リボン */
.ribbon {
    overflow: hidden;
}
.ribbon h1 {
	width: 175px;
	height: 30px;
	text-align: center;
    background-color: rgba(255, 0, 0, 0.8);
    position: absolute;
    left: -35px;
    top: 25px;
	padding-top: 4px;
    transform: rotate(320deg);
}
.slideIn:nth-child( 2 ){
	animation: SlideIn 1.2s ease-in-out;
}
.slideIn:nth-child( 3 ){
	animation: SlideIn 1.4s ease-in-out;
}
.slideIn:nth-child( 4 ){
	animation: SlideIn 1.6s ease-in-out;
}
.slideIn:nth-child( 5 ){
	animation: SlideIn 1.8s ease-in-out;
}
.shiny {
	position: relative;
}
.shiny:nth-child(2)::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 0;
    left: 0;
    width: 35px;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 100%);
	transform: skewX(-25deg);
    animation: shiny 11s ease-in-out infinite;
	animation-delay: 2s;
}
.shiny:nth-child(3)::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 0;
    left: 0;
    width: 35px;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 100%);
	transform: skewX(-25deg);

    animation: shiny 11s ease-in-out infinite;
	animation-delay: 5s;
}
.shiny:nth-child(4)::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 0;
    left: 0;
    width: 35px;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 100%);
	transform: skewX(-25deg);
    animation: shiny 11s ease-in-out infinite;
	animation-delay: 8s;
}
.shiny:nth-child(5)::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 0;
    left: 0;
    width: 35px;
    height: 100%;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 100%);
	transform: skewX(-25deg);
    animation: shiny 11s ease-in-out infinite;
	animation-delay: 11s;
}

@keyframes shiny {
	30% { left: 10%; opacity: 0;}
	35% { left: 20%; opacity: 1; }
	40% { left: 70%; opacity: 1; }
	45% { left: 80%; opacity: 0.8;}
	/* ここで間隔をあけている */
	100% { left: 10%; opacity: 0;}
}


@keyframes SlideIn {
	0% {
		opacity: 0;
		transform: translateX(-64px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
} 
</style>
